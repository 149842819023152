export enum Networks {
  mail = 'mail',
  facebook = 'facebook',
  twitter = 'twitter',
  pinterest = 'pinterest'
}

type ObjectParams = {
  [key: string]: string;
};

type NetworksProps = {
  network: Networks;
  url: string;
  media: string;
};

const objectToGetParams = (object: ObjectParams) => {
  const params = Object.keys(object).filter(key => !!object[key]);

  return (
    '?' +
    params.map(key => `${key}=${encodeURIComponent(object[key])}`).join('&')
  );
};

const generators = ({ network, url, media }: NetworksProps) => {
  switch (network) {
    case Networks.mail:
      return `mailto:${objectToGetParams({
        subject: 'I wanted you to see this post from Parsley Health',
        body: `Checkout this post, \n\n» ${url}`
      })}`;
    case Networks.facebook:
      return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
        u: url
      })}`;
    case Networks.twitter:
      return `https://twitter.com/share${objectToGetParams({
        url: url
      })}`;
    case Networks.pinterest:
      return `https://pinterest.com/pin/create/button/${objectToGetParams({
        media: media
      })}`;
    default:
      return '';
  }
};

const getNetworkLink = ({ network, url, media }: NetworksProps) => {
  if (!url) {
    return '';
  }

  if (network === 'pinterest' && !media) {
    throw new Error(
      `the 'media' prop for the 'Pinterest' network can't be undefined`
    );
  }

  return generators({ network, url, media });
};

export default getNetworkLink;
