import { useStaticQuery, graphql } from 'gatsby';
import { SanityBlogConfig, SanityBlogSidebarCard } from 'graphql-types';

interface TResponse extends SanityBlogConfig {
  SidebarWhatIsPH: SanityBlogSidebarCard;
  Sidebar150Off: SanityBlogSidebarCard;
}

export const useBlogConfig = (): TResponse => {
  const { blogConfig, sidebars } = useStaticQuery(
    graphql`
      fragment MetaImage on SanityImage {
        hotspot {
          y
          x
          width
          height
        }
        crop {
          bottom
          left
          right
          top
        }
        asset {
          url
        }
      }
      fragment MetaDataBlog on SanityMetaDataBlog {
        metaTitle
        metaDescription
        metaImage {
          ...MetaImage
        }
        noindex
      }
      fragment MetaDataGuide on SanityMetaDataGuide {
        metaTitle
        metaDescription
        metaImage {
          ...MetaImage
        }
        noindex
      }
      query getBlogConfig {
        blogConfig: sanityBlogConfig {
          blogFreeConsult {
            ...SanityTextBlockSectionFragment
          }
          metaDataBlog {
            ...MetaDataBlog
          }
          metaDataGuide {
            ...MetaDataGuide
          }
          sidebarTitle
          sidebarCard {
            heading
            body: _rawBody
            image {
              _type
              ...SanityImageFragment
            }
            cta {
              ...SanityButtonsFragment
            }
          }
          mainFeaturedPost {
            ...BlogPostCardInfo
          }
          mainFeaturedVideo {
            ...BlogVideoCardInfo
          }
        }
        sidebars: allSanityBlogSidebarCard {
          nodes {
            _id
            heading
            body: _rawBody
            image {
              _type
              ...SanityImageFragment
            }
            cta {
              ...SanityButtonsFragment
            }
          }
        }
      }
    `
  );

  const getSideBar = (id: string) => {
    return sidebars.nodes.find((s: SanityBlogSidebarCard) => s._id === id);
  };

  const SidebarWhatIsPH = getSideBar('3f1e68b3-02b2-4750-807e-edbae1a2b70d');
  const Sidebar150Off = getSideBar('e05500d7-5ff0-479b-bdc8-79d741a8c846');

  return { ...blogConfig, SidebarWhatIsPH, Sidebar150Off };
};
