import React from 'react';
import TextBlockSectionWrapper from '../../text-block-section-wrapper';
import { useBlogConfig } from '../../../hooks/use-blog-config';
import './blog-banner.css';

export const BlogBanner = () => {
  const { blogFreeConsult } = useBlogConfig();
  return (
    <TextBlockSectionWrapper {...blogFreeConsult} className="blog-banner" />
  );
};
