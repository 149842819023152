import React from 'react';
import styled from '@emotion/styled';
import { theme, Palette } from '@parsleyhealth/cilantro-ui';
import FbIcon from '../../assets/icon-facebook.svg';
import TwIcon from '../../assets/icon-twitter.svg';
import PtIcon from '../../assets/icon-pinterest.svg';
import MlIcon from '../../assets/icon-mail.svg';
import getNetworkLink, { Networks } from '../../lib/get-network-link';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

const IconProps = `
  fill: ${Palette.lightgrayTitle};
  cursor: pointer;
  &:hover {
    fill: ${Palette.lightGreen};
  }
`;

const FacebookIcon = styled(FbIcon)`
  ${IconProps};
  margin-left: ${theme.space.xs}px;
  margin-right: ${theme.space.xs}px;
`;

const TwitterIcon = styled(TwIcon)`
  ${IconProps};
  margin-right: ${theme.space.xs}px;
`;

const PinterestIcon = styled(PtIcon)`
  ${IconProps};
  margin-right: ${theme.space.xs}px;
`;

const MailIcon = styled(MlIcon)`
  ${IconProps};
`;

const Link: React.FC<{
  url: string;
  network: Networks;
  media?: string;
  children: any;
}> = ({ children, network, url, media }) => (
  <a
    href={getNetworkLink({
      network,
      url,
      media
    })}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
);

const BlogShareSocialMedias: React.FC<{
  url: string;
  pinterestMedia?: string;
}> = ({ url, pinterestMedia }) => (
  <Container>
    <Link network={Networks.facebook} url={url}>
      <FacebookIcon />
    </Link>
    <Link network={Networks.twitter} url={url}>
      <TwitterIcon />
    </Link>
    {pinterestMedia && (
      <Link network={Networks.pinterest} media={pinterestMedia} url={url}>
        <PinterestIcon />
      </Link>
    )}
    <Link network={Networks.mail} url={url}>
      <MailIcon />
    </Link>
  </Container>
);

export default BlogShareSocialMedias;
